body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Libre Baskerville",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */

  font-family: 'Lato', sans-serif;
font-family: 'Open Sans', sans-serif;
font-size: 12px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



#rhap_current-time{
  color: white !important;
}

.rhap_total-time{
  color: white !important;
}

.rhap_repeat-button, .rhap_rewind-button, .rhap_play-pause-button, .rhap_forward-button, .rhap_volume-button{
  color: white !important;
}

.rhap_volume-indicator, .rhap_progress-indicator{
  background-color: white !important;
}