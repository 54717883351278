@import url(https://fonts.googleapis.com/css2?family=Cabin&family=Dosis&family=Poppins:wght@700&family=Roboto:wght@400;500&display=swap);
body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Libre Baskerville",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */

  font-family: 'Lato', sans-serif;
font-family: 'Open Sans', sans-serif;
font-size: 12px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



#rhap_current-time{
  color: white !important;
}

.rhap_total-time{
  color: white !important;
}

.rhap_repeat-button, .rhap_rewind-button, .rhap_play-pause-button, .rhap_forward-button, .rhap_volume-button{
  color: white !important;
}

.rhap_volume-indicator, .rhap_progress-indicator{
  background-color: white !important;
}
.main-nav-class{
    /* background-color:#EC8444;#1E3760 */
    /* background-color:var(--khaki); */
    display: flex;
    justify-content: center !important;
    flex-direction: row;
    align-items: center !important;
    /* padding: 1.5em 0 !important; */
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: row;
    font-family: "Lato";
}

.navbar-div-logo{
}
.navbar-div-text{
    color: #251d25;
}

.nav-bar-div-text-h{
    color: #251d25;
    font-size: x-large !important;
}

.mrinh6{
    margin:0 !important;
    display: flex;
    align-items: center;
}

.nav-logo-div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
 
}

.nav-logo-img{
    flex-basis: 20%;
    width: 100%;
    object-fit: contain;
    height: 100px;
    mix-blend-mode: multiply;
}


/* @media only screen and (max-width: 600px) {
   .navbar-div {
       flex-direction: column;
   }
   .navbar-div-logo {
       flex-basis: 100%;
       width: 100%;
   }

   .nav-bar-div-text-h{
    font-size: 33px !important;
}

.navbar-div-text1{
    flex-basis: 100%;
    justify-content: center;
    align-items: center;
}
}
    
.navbar-div-text-right{
   flex-basis: 60%;
   display: flex;
   justify-content: flex-end;
   padding-right: 10vw;
}
.list-styled{
    text-align: center;
    list-style-type: square;
}
.navbar-corner{
    
    width: 70%;
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 600px){
    .navbar-div-text-right{
        flex-basis: 100%;
        padding-right: 0;
        
    }
}
.logo-img{
    width: 80px
    
}
.navitems{
    font-weight: 300
}

.div-details-navbar{
    font-size: 0.7em;
}

.online-admission{
    background-color: white;
    padding: 10px;
    border: none;
    box-shadow: 1px 2px 3px rgba(0,0,0,0.1)
}


/* Small nav Div 


.navbar-div-small{
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 5vh;
    padding-left: 10vw;
    padding-right: 10vw;
}

.caret-right{
    outline: none;
}

.caret-right::after{
    content: "";
    float: right;
    margin-top: .5em;
}

.right-drop{
    transform: translate3d(110px, -8px, 0px) !important;
}


/* Responsive 

@media only screen and (max-width: 600px){
    .navbar-div{
        
        padding-left: 0vw;
        padding-right: 0vw;
    }

    .logo-img{
        width: 60px
    }

    .navbar-div-text h5{
        font-size: 0.8em
    }

    .navbar-div-text h6{
        font-size: 0.5em
    }

    .navbar-div-text {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

 */

.card-date-news{
    background-color: #1f1f1f;
    color: white !important;
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
:root {
  --kashmir:#426C92;
  --khaki:#BDB492;
  --clay:#896C3E;
  --nepal:#98B4C3;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.grade-a-text{
  font-size: 22px; 
  font-weight: 900;
  color: #DC3D1F;
  -webkit-animation: colorchange 1s infinite;
          animation: colorchange 1s infinite;
  
}

@-webkit-keyframes colorchange {
  from {
    color: #DC3D1F;
    
  }
  to {
    color: #e59866;
    
  }
}

@keyframes colorchange {
  from {
    color: #DC3D1F;
    
  }
  to {
    color: #e59866;
    
  }
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


a:hover {
  text-decoration: none !important;
}

.navStyle-new{
  width: 100%;
}


.faculty-image {
  width: 100%; 
  height: 30vh;
  box-shadow: 1px 2px 10px rgba(0,0,0,0.3);
}

@media only screen and (min-width: 600px) {
  .navStyle-new {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: flex-end;
    width: 100%;
    background-color: white !important;
    background-color: #b94735 !important;
  } 

  .navStyle-new-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #001529 !important;
    
  } 

  .m-display{
    display: none !important;
  }

  .m-none{
    display: unset !important;
  }

}




.departmental-header-text{
  font-weight: 500 !important;
}


.carousel-main-image{
  width: 100%;
  min-height: 60vh;
}

@media only screen and (max-width: 600px) {
  .faculty-image {
    width: 80%; 
    height: 80%;
    box-shadow: 1px 2px 10px rgba(0,0,0,0.3);
    margin-bottom: 20px;
  }


  .departmental-header-text{
    font-size: 14px !important;
  }

  /* .m-display{
    display: unset !important;
  }
  */

  .m-none{
    display: none !important;
  } 
  
}



.new-principal-div {
  border: solid 3px grey;
  padding: 20px;
  background-color: #fff6ca;
}

.bg-primary {
  background-color: #001529!important;
}

.ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
  font-weight: 700;
}

/* new class created by mrin*/

.hr{
  height:2px;
  background: white;
  width:900px;
}

.round-date{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  font-weight: 600;
  color:white;
  border-radius:50%;
  height:100px;
  width:100px;
  border:3px solid white;
}
/* 
font-family: 'Cabin', sans-serif;
font-family: 'Dosis', sans-serif;
font-family: 'Poppins', sans-serif;
font-family: 'Roboto', sans-serif; */

.fontFix{
  /* font-family:  'Poppins', sans-serif; */
}

.round{
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #BDB492;
  background: var(--khaki);
  color:#251D25;
  border-radius: 50%;
  padding: 1em;
  margin:3em;
  transition-duration:5sec;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-delay: 100ms;
  -o-transition-timing-function: ease-in-out;
  cursor: pointer;
}
.round:hover{
  background-color: #98B4C3;
  background-color: var(--nepal);
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}



.news{
  background: #2D3192;
}

.news-title{
  color:#B94735;
  cursor: pointer;
  font-weight: 400 !important;
}

.news-title:hover{
  color:#251D25

}

.vdamaz{
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
  -webkit-animation: ease-in-out 2s;
          animation: ease-in-out 2s;
}

.vdamaz:hover{
  -webkit-transform: scale(1);
          transform: scale(1);
}

.navItemPandu{
  color:white !important;
  border-bottom:none !important;
  padding: 0 0px !important;
  font-weight: 600;
  font-size: 12px;
}

.navItemPandu:hover{
  background: #251D25 !important;
}

.footer{
  background: #080927 !important;
  color:#EBDB86;
  width:100%;
}

.foot1h{
  color:#EBDB86;
}

.add p{
  font-size:medium;
  margin:0;
  padding:0;
}

.MuiListItem-button:hover {
  background-color: #EBdB86 !important;
}

.logo-img{
  width:80px;
  text-align: center;
}

@media only screen and (max-width: 1024px) {
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
    margin: 0 10px !important;
  }
}


@media only screen and (max-width: 768px) {
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
    margin: 0 10px !important;
  }

  .nav-bar-div-text-h {
    color: #251d25 !important;
    font-size: x-large !important;
    text-align: center;
  }

  .ant-menu-submenu-popup > .ant-menu {
    background-color: #b94735 !important;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #b94735 !important;
  }
}

@media only screen and (max-width: 768px) {
  .row {
    display: flex !important;
    flex-wrap: wrap !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .carousel-main-image{
    width: 100%;
    min-height: 200px;
  }
}

.feestable{
  margin: 1em 0;
}

.feestable td{
  font-weight: 400;
  padding:5px 30px;
}

.noticecard{
  background: transparent;
  background-color: white;
  padding: 10px;
  border-bottom: 2px solid black;
}

.subhead{
  border-bottom: 2px solid black;
  background-color: wheat;
  font-size: 22px;
  padding: 10px;

}

.subhead:hover{
  border-bottom: 2px solid black;
}

@media (min-width: 1400px){
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 80% !important;
  }

  .gapround{
    padding: 0 50px;
  }
}

p{
  line-height: 1.875em !important;
}



.table > thead > tr > td {
  background-color: #3b87eb;
  color: white;
  /* border-color: white; */
  font-weight: bold;
  padding: 3px;
  text-align: center;
  font-size: 12px;
}
.table > tbody > tr > td {
 
  padding: 3px;
  font-size: 12px;
  font-weight: 500;
}

p {
  margin: 0!important;
}


.footer-image-large{
  width : 50%;
  border-radius: 30px;
   box-shadow: 10px 20px 30px rgba(0,0,0,0.3)
}


@media only screen and (max-width: 700px){
  .footer-image-large{
    width : 100%;
   
  }
}
.actual-padding{
    padding-top: 3vw;
    padding-bottom: 4vw;
}
.list-styled{
    text-align: left
}

.title{
    color: white
}
